.success-page-button{
   
    background-color: #007BFF;
    color: white;
    font-weight: bold;
    padding: 12px 18px;
    font-size: medium;
    margin-top: 2vw;
    width: 25vw;
    border: none;
    border-radius: 25px;
    cursor: pointer;
   
}


@media screen and (max-width: 768px) {
    .success-page-button{
        width: 80vw;
    }
 }

