/* src/components/FAQ.css */
.faq-container {
    padding: 30px;
    width: 100%;
    margin: 0 auto;
    background-color: #121212;
    margin-bottom: 20px;
    padding-left: 22.5vw;
    padding-right: 22.5vw;
    margin-top: 150px;

  }
  
  .faq-container h2 {
    text-align: center;
    text-align: left; 
    margin-bottom: 20px;
  }
  
  .faq-list {
    list-style-type: none;
    padding: 0;
  }
  
  .faq-item {
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
  
  .faq-question {
    font-weight: bold;
    color: #fff;
    margin-bottom: 5px;
    text-align: left; 
  }
  .faq-answer{
    color: #fff;
    text-align: left; 
  }
  
  @media screen and (max-width: 768px) {
    .faq-container {
      width: 100%;
      border-radius: 0px;
      margin-bottom: 0px;
      padding-left: 30px;
      padding-right: 30px;
    }
}
  