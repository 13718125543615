/* General Header Styles */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  height: 60px;
  background-color: #fff;
}

.logo-container {
  display: flex;
  align-items: center;
}

.add-icon {
  color: #007BFF;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.account-icon{
  color: #fff;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.logo {
  width: 3vw;
  height: 3vw;
  margin-right: 20px;
  align-items: center;
}

.title-container {
  flex-grow: 1;
  align-items: center;
}

.title {
  font-size: 24px;
  margin: 0;
}

.subtitle {
  font-size: 14px;
  margin: 0;
  color: #aeaeae;
}

.navigation {
  display: flex;
  align-items: center;
}

.nav-links {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav-link {
  margin-right: 20px;

}

.nav-link a {
  text-decoration: none;
  color: #aeaeae;
}

.nav-link a.active {
  font-weight: bold;
  color: #fff;
}

/* Checkout Button and Form */
.checkout-form {
  margin-left: 20px;
}

#checkout-button {
  background-color: #121212;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.add-credits-text {
  color: #007BFF;
  margin-left: 10px;
}

.credits-icon {
  margin-right: 5px;
  margin-left: 5px;
  color: #fff;
}

.bar-icon{
  width: 24px;
  height: 24px;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .subtitle {
      display: none;
  }

  .title {
      font-size: 18px;
  }

  .header {
      padding: 2px 4px;
  }

  .nav-links {
      display: none; /* Hide desktop navigation on mobile */
  }

  .logo {
      width: 8vw;
      height: 8vw;
      margin-right: 20px;
  }

  #checkout-button {
    background-color: #121212;
      border: none;
      padding: 1px;
      cursor: pointer;
      font-size: 15px;
      font-weight: bold;
  }

  .checkout-form {
      margin-left: 5px;
  }

  /* Mobile Menu Styles */
  .mobile-menu-button {
      width: 20px;
      height: 20px;
      cursor: pointer;
      color: #fff;
  }

  .mobile-menu {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 75%;
      height: 100%;
      background-color: #000;
      box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
      z-index: 1000;
      padding: 20px;
      transform: translateX(-100%);
      transition: transform 0.3s ease-in-out;
  }

  .mobile-menu.open {
      display: block;
      transform: translateX(0);
  }

  .mobile-nav-links {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      color: #fff;
      gap: 20px;
  }

  .mobile-nav-link {
      text-align: left;
      color: #fff;
  }

  /* Highlight Selected Nav Link */
  .mobile-nav-link a.active {
    font-weight: bold;
  }
}


/* Disable Mobile Menu on Larger Screens */
@media screen and (min-width: 769px) {
  .mobile-menu-button,
  .mobile-menu {
      display: none;
  }

  .nav-links {
      display: flex; /* Ensure desktop nav links are shown on larger screens */
  }
}