.meme-feed {
    width: 70vw;
    display: flex;
    flex-wrap: wrap; /* Allows wrapping of items to the next row */
    justify-content: space-between; /* Adds space between items */
  }
  
  .meme-item {
    width: 34.5vw; /* Adjust the width to show 2 memes per row */
    margin-bottom: 10px;
  }

@media screen and (max-width: 768px) {
    .meme-feed,
    .meme-item {
        width: 100%; /* Full width on mobile */
      }
}