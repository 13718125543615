.App {
    background-color: #000000;
}

.sticky-tablist {
    position: sticky;
    top: 60px; /* Adjust this value to the height of your header */
    z-index: 1000; /* Ensure it stays above other content */
    background-color: #000; /* Match background color */
}

.full-width-tablist {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: #000;
}

.full-width-tab {
    flex: 1;
    text-align: center;
    padding: 10px;
    cursor: pointer;
    background-color: #000;
    color: #fff;
    border: none;
    outline: none;
}
.full-width-tab:focus {
    outline: none; /* Remove the focus outline on tab focus */
}


.full-width-tab[aria-selected="true"] {
    border-bottom: 3px solid #fff;
    background-color: #000;
    color: #fff;
}
