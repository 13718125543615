/* App.css */
.App-ie {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.App-ie {
    text-align: center;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.error-message {
    color: red;
    font-size: 1px;
    margin-top: 5px;
  }

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

h1 {
    margin-bottom: 20px;
    font-size: 3em;
    color: #333;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}
.image{
    user-select: none;
    
}



.input-hint{
   
}

.image-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    position: relative;
}

.image-preview {
    position: relative;
    max-width: 35vw;
    overflow: hidden;
    background-color: #fff;
    margin-bottom: 20px;
}


.image {
    max-width: 100%;
    height: auto;
    display: block;
}


.download-button {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #F0F0F0;
    color: #000000;
    font-weight: bold;
    padding: 12px 25px;
    margin: 8px 0;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}

.remove-bg-button {
    position: fixed;
    left: 0;
    top: 30%;
    background-color: #fff;
    color: #007BFF;
    margin-left: 0.5vw;
    padding: 10px 20px;
    border: 1px solid #007BFF;
    border-radius: 100px;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-top: 10px;
    gap: 5px;
    transition: background-color 0.3s, color 0.3s;
}


.meme-text {
    font-weight: bold;
    font-style: normal;
    font-size: xx-large;
    padding-left: 10px;
    padding-right: 10px;/* or any desired line height */
    white-space: pre-wrap; /* Preserve whitespace */
  }
  .watermark-prompt{
    color: gray;
    margin-top: 5px;

  }

  .meme-generation-container {
    margin-top: 100px;
    margin-bottom: 20px;
    width: 70vw;
    background-color: #121212;/* Semi-transparent black background */
    padding: 10px;
    padding-top: 10px; /* Add padding around the content *//* Optional: Add rounded corners */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .font-dropdown {
    padding: 10px 15px;
    border-radius: 10px;
    font-size: 14px;
    color: #fff;
    /* Add space from the right */
    padding-right: 20px; /* Adjust this value to create the desired space */
    /* Custom styling for the select box */
    background: url('./arrow_drop_down.svg') no-repeat right center;
    background-size: 24px; /* Adjust the size of the arrow icon */
}

/* Ensure the select element has no default styling */
.font-dropdown::-ms-expand {
    display: none; /* Hide the default arrow in IE/Edge */
}

/* Style the select element in Webkit browsers (Chrome, Safari) */
.font-dropdown {
    -webkit-appearance: none; /* Remove default arrow in Webkit browsers */
    appearance: none; /* Remove default arrow in other browsers */
}

.remove-bg-button:disabled {
    cursor: not-allowed;
}



.dropzone {
    border: 1px dashed #555;
    border-radius: 15px;
    margin-bottom: 10px;
    margin-top: 15px;
    cursor: pointer;
    padding: 50px 0px 50px 0px;
    width: 50vw;
    background-color: #fff;
    transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s;
}

.dropzone:hover {
    background-color: #f0f8ff;
    border-color: #0056b3;
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
}

.dropzone-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.input-button-container {
    display: flex;
    align-items: center;
}

.prompt-container {
  
    bottom: 0;
  
 
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
     /* Adjust to create space for the edit button */
}


.prompt-input {
    width: 68vw;
    padding: 18px;
    border: 0px;
    border-radius: 25px;
    box-sizing: border-box;
    font-size: 16px;
    margin-bottom: 5px;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.prompt-input-bottom {
    width: 68vw;
    padding: 15px;
    background-color: #2A2A2A;
    border-radius: 25px;
    font-size: 15px;
}

.custom-input {
    color: #fff; /* Change typing text color to white */
    /* Other styling properties */
    border: none; /* No border initially */
    cursor: text; /* Default cursor for text input */
    transition: border-color 0.3s ease, box-shadow 0.3s ease; 
    caret-color: white;/* Smooth transition */
}

.custom-input:focus {
    border: 1px solid #fff; /* Border color when focused */
    outline: none; /* Remove default outline */
}

.edit-button-container{
    display: flex;
    justify-content: center;
}
.edit-button {
    display: flex; /* Add this line */
    align-items: center; /* Ensure items are centered vertically */
    justify-content: center; /* Center items horizontally */
    background-color: #007BFF;
    color: white;
    font-weight: bold;
    padding: 10px 3vw;
    width: 25vw;
    margin-top: 20px;
    font-size: medium;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s;
    /* Adjust to create space between input and button */
    /* Set width to fit content */
}

.edit-button-image {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F0F0F0;
    color: #000;
    font-weight: bold;
    padding: 10px 3vw;
    margin-top: 10px;
    border: none;
    width: 25vw;
    font-size: medium; /* Updated to include a solid border */
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s;
    /* Adjust to create space between input and button */
    /* Set width to fit content */
}


  /* Add these styles to your existing CSS file */

.loading-bar-container {
    width: 100%;
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 10px;
  }

  .memeText-image{
    max-width: 25vw ;
    font-size: large;
    font-family:fantasy;
    font-weight: bold;
  }



  .memeText{
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: bolder;
  }
  
  .loading-bar {
    height: 100%;
    background-color: #007BFF;
    width: 0;
    transition: width 0.4s;
  }
  
  
.edit-button img {
    margin-right: 5px;
}

.edit-button:disabled {
    background-color: #3e3e3e;
    opacity: 20%;
    cursor: not-allowed;
}

.edit-button-image:disabled {
    opacity: 20%;
    cursor: not-allowed;
}


.arrow {
    width: 50px;
    height: 50px;
    margin-top: 10px;
    transition: transform 0.3s;
}

.arrow:hover {
    transform: rotate(20deg);
}

.model-selection-container{
 margin-top: 10px;
 margin-bottom: 10px;
}

.download-button {
    display: flex;
    align-items: center;
  }
  
  .icon-text-wrapper {
    display: flex;
    align-items: center;
    margin-left: 4px; /* Adjust as needed */
  }
  
  .icon {
    margin-right: 4px; /* Adjust as needed */
  }

.comparison-container {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50vw;
    padding: 10px;
}

.maskedImageContainer {
    position: relative;
    display: inline-block;
  }
  
  .maskCanvas {
    position: absolute;
    top: 0;
    left: 0;
  }

 
  

.video{
    width: 25vw;
    margin-right: 10px; 
}

.tutorial-text{
    font-weight: bold;
    margin-bottom: 20px;
}

.comparison-image {
    width: 100%;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    margin-bottom: 10px;
}

.image-wrapper {
    width: 50%;
    margin-bottom: 10px;
    padding: 5px;
}

.comparison-text {
    flex: 1;
    text-align: center;
    min-width: 200px;
    background-color: #fff;
    padding: 25px;
    border-radius: 15px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.comparison-text h3 {
    margin-top: 0;
    font-size: 1.7em;
    color: #007BFF;
}

.comparison-text p1 {
    font-size: 1.1em;
    color: #555;
}
.model-button {
    padding: 10px 15px;
    border-radius: 100px;
    border: none;
    background-color: #3E3E3E;
    color: #fff;
    font-size: 14px;
    font-weight: normal;
    margin-left: 5px;
    margin-top: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

   
}

  

.selected {
    background-color: #fff;
    color: #000;
    padding: 10px 15px;
    border: none;
    border-radius: 100px;
    font-size: 14px;
    font-weight: normal;
    margin-left: 5px;
    margin-top: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
     /* Change this to the desired color for the selected state */
}

.edited-image-container {
    position: relative;
    width: fit-content;
}

.edited-image-container img {
    display: block;
}

.edited-image-text-overlay {
    position: absolute;
    font-weight: bold;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    text-align: center;
    padding: 10px;
    font-size: 18px;
    box-sizing: border-box;
    border-radius: 0px 0px 15px 15px;
}

.links-container ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
}

.links-container li {
    margin: 0 1em;
}

.links-container {
    margin-top: 10px;
   
}

ul li a {
    text-decoration: none;
    color: #555;
    font-size: 14px;
    display: inline-block;
}

.links-container a:hover {
    text-decoration: underline;
}

body, html {
    margin: 0;
    padding: 0;
    height: 100%;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.App {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    margin-bottom: 15px;
    background-color: #121212;
    display: flex;
    padding: 10px;
    align-items: center;
    z-index: 1000;
}


  
  /* You can also add a custom focus style if needed */
  .no-outline:focus {
    outline: none;

  }
  



.title-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.title {
    font-size: 1.25em;
    margin-bottom: 3px;
    color: #fff;
}

.subtitle {
    font-size: 1em;
    color: #aeaeae;
}

.upload-button {
    
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #007BFF;
    color: white;
    font-weight: bold;
    padding: 12px 18px;
    font-size: medium;
    width: 25vw;
    margin: 8px 0;
    margin-bottom: 20vh;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}

.report-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
  
  .popup-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  textarea {
    width: 100%;
    height: 100px;
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  

.upload-button img {
    margin-right: 20px;
}

.upload-button:hover {
    background-color: #0056b3;
}


.loading-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    color: #0056b3;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.8);
    padding: 10px;
    border-radius: 10px;
}

.loading-overlay p {
    margin-top: 10px;
    font-size: 14px;
    color: #333;
}

.mode-toggle {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .mode-button {
    padding: 10px 20px;
    margin: 0 5px;
    cursor: pointer;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    border-radius: 4px;
  }
  
  .mode-button.selected {
    background-color: #007bff;
    color: white;
  }

  .edit-button-container {
  /* Optional: Adds space between buttons */
  }
  
  .edit-button {
    margin-bottom: 10px; /* Optional: Adds space below each button */
  }
  
  
  .manual-mode-inputs input {
    display: block;
    margin-bottom: 10px;
  }
  



@media screen and (max-width: 768px) {
    .App-ie {

        padding-top: 20px;
        padding-bottom: 20px;
        width: 100vw;

    }

    

    .image-container,
    .dropzone-container,
    .comparison-container,
    .prompt-container,
    .input-button-container,
    .links-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }


    .video{
        width: 80vw;
        margin-right: 0px; 
    }

    .image-preview {
        max-width: 100%;
        max-height: 100%;
    }
    .meme-text{
     
    }
    

   

    .memeText-image{
        max-width: 100% ;
        font-size: large;
        font-family:fantasy;
        font-weight: bold;
        
      }
    
      .draggable-meme-text {
        position: absolute;
        cursor: move;
        color: white;
        font-size: 30px;
        font-family: Arial, sans-serif;
        text-shadow: 2px 2px 4px #000000;
      }

      

    .dropzone-container{
        display: none;
    }

    .dropzone {
        width: 80vw;
        height: auto;
        visibility: visible;
        padding: 20px;
    }
    .meme-generation-container{
        width: 100vw;
        border-radius: 0px;
        margin-top: 100px;
    }

    .prompt-input,
    .prompt-input-bottom,
    .upload-button,
    .edit-button,
    .edit-button-image,
    
    .comparison-image {
        width: 90vw;
        font-size: 14px;
        margin-bottom: 10px;
    }

    .edit-button{
        margin-bottom: 0px; 
    }

  
    .watermark-prompt {
        font-size: 15px;
        font-weight: normal;
        margin-top: 10px;
        margin-left: 5vw;
        margin-right: 5vw;

    }


    .links-container {
       margin-top: 10px;
    }
    

    .prompt-container {
        bottom: 10px;
        transform: translateX(0);
        margin-bottom: 0px;

  
            z-index: 0;
          
             /* Adjust to create space for the edit button */
        
    }

    .input-button-container {
        flex-direction: column;
        margin-bottom: 10px;
    }
    .prompt-input-bottom {
      
        margin-left: 0vw;
       
    }

    .edit-button {
        margin-left: 0;
        margin-top: 10px;
    }

    .comparison-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 10px;
        margin-top: 20px; /* Adjust margin-top as needed */
    }

    .comparison-image {
        width: 100%;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        margin-bottom: 10px;
    }

    .comparison-text {
        text-align: center;
    }

    .links-container {
        bottom: 0;
        left: 0;
        transform: translateX(0);
        z-index: 0;
        flex-direction: column;
    }

    .image-wrapper {
    width: 90vw;
    }

    .edited-image-text-overlay {
        font-size: 16px;
    }

    .model-selection-container {
        width: 100%;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        position: relative;
    }

    .loading-bar-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .loading-bar-container {
        width: 90vw;
        margin: 0 auto; /* Center align horizontally */
    }

  
    .remove-bg-button {
        position: relative;
        margin-top: 20px;
    }
}