.popup-overlay-account {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-content-account {
    background-color: #000;
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}

.close-icon-account {
    align-self: flex-end;
    cursor: pointer;
    color: white;
    width: 24px;
    height: 24px;
}

.email-account {
    margin: 10px 0;
    font-size: 18px;
    color: #fff;
    font-weight: bold;
}

.privacyLink-account {
    margin: 10px 0;
    list-style: none;
  
}

.privacyLink-account a {
    color: #fff;
    text-decoration: none;
}

.footer-link-twitter {
    margin: 10px 0;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Aligns items to the left */
}

.footer-link-twitter .twitter-logo-account {
    margin-right: 8px;
}

.button-login-account {
    cursor: pointer;
  padding: 12px 50px;
  border-radius: 100px;
  border: none;
  background-color: #007BFF;
  color: white;
  font-weight:bold;
  font-size: medium;
}

.button-login-account:hover {
    background-color: #0056b3;
}
