/* BottomBanner.css */
.bottom-banner {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 300px; /* Adjust the max-width to scale the banner as needed */
    text-align: center;
    z-index: 1000;
    padding: 10px;
    border-radius: 5px;
  }
  
  .bottom-banner img {
    max-width: 50vw; /* Adjust the max-width to scale the image as needed */
    width: 100%;
    height: auto;
  }
  
  .close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(255, 255, 255, 0.8); /* Light background for visibility */
    color: black; /* Text color for contrast */
    border: none;
    border-radius: 50%;
    cursor: pointer;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  