.footer {
    display: flex;
    justify-content: space-between; /* Distributes space between left and right sections */
    align-items: center; /* Aligns items vertically in the center */
    padding: 10px 20px; /* Adds padding to the footer */
    background-color: #121212; /* Set background color */
    width: 100vw; /* Ensure the footer spans the entire viewport width */
    box-sizing: border-box; /* Includes padding in the element's total width and height */
    position: relative; /* Ensures the footer is positioned relative to its container */
    left: 0; /* Aligns the footer to the left of the viewport */
}

.footer-right {
    display: flex;
    align-items: center; /* Aligns items vertically in the center */
    justify-content: center; /* Aligns items horizontally in the center */
}

.links-container-footer ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    margin-left: 7px;
}

.privacyLink-footer {
    margin-left: 7px;
    color: #AEAEAE;
}

.links-container-footer li {
    /* Additional styles for list items if needed */
}

ul li a {
    text-decoration: none;
    color: #AEAEAE;
    font-size: 14px;
    display: inline-block;
}

.links-container-footer a:hover {
    text-decoration: underline;
}

.footer-left, .footer-right {
    display: flex;
    align-items: center; /* Aligns items vertically in the center */
}

.logo-container-footer {
    /* Space between the logo and title */
}

.logo {
    width: 30px;
    height: 30px;
    /* Adjust size as needed */
}

.title-container-footer {
    /* Additional styles for the title container if needed */
}

.title-footer {
    font-size: 20px; /* Adjust the font size of the title */
    margin: 0;
    color: #fff; /* Remove default margin */
}

.footer-link {
    text-decoration: none;
    margin-left: 7px; /* Space between links */
    color: #fff;
    font-size: 14px; /* Set text color */
}

.footer-link-twitter {
    display: flex;
    align-items: center; /* Aligns the icon vertically */
    margin-left: 7px; /* Space between links */
    color: #AEAEAE;
}

.twitter-logo {
    width: 24px; /* Adjust size as needed */
    height: 24px; /* Adjust size as needed */
    vertical-align: middle; /* Aligns the icon vertically */
}

.subtitle-footer {
    color: #AEAEAE;
}

@media screen and (max-width: 768px) {
    .title-footer {
        display: none;
    }

    .subtitle-footer {
        display: none;
    }
}
