/* Popup.css */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  position: relative; /* Ensure relative positioning for the close icon */
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  width: 25px;
  height: 25px;
  color: #fff;
}
.stripe-logo{
 max-width:4vw;
  max-height:3vw;    

}
.secure-checkout{
  max-width:10vw;
  max-height:7vw;
  margin-right: 5px;
  font-size: medium;
  color: gray;   
}


.popup-content {
  background: #000;
  padding: 20px;
  border-radius: 20px;
  text-align: center;
/* Ensure popup content doesn't exceed 80% of the viewport height */
  overflow-y: auto; 
}


.plan-heading {
  background-color: #ffffff;
  border: none;
  margin-top: 10px;
  
  margin-bottom: 10px;
  font-size: xx-large;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center; /* Center horizontally */
  text-align: center; /* Optional: Center text horizontally */
  height: 100%;
  margin-bottom: 10px; /* Ensure it takes full height of parent */
}

.price-heading {
  background-color: #ffffff;
  border: none;
  margin-top: 10px;
  
  margin-bottom: 10px;
  font-size: xx-large;
  font-weight:normal;
  display: flex;
  align-items: center;
  justify-content: center; /* Center horizontally */
  text-align: center; /* Optional: Center text horizontally */
  height: 100%;
  margin-bottom: 20px; /* Ensure it takes full height of parent */
}
.price-text-popup {
  color: #fff;
  border: none;
  
  font-size: x-large;
  font-weight:normal;
  display: flex;
  align-items: center;
  justify-content: center; /* Center horizontally */
  text-align: center; /* Optional: Center text horizontally */
  height: 100%; 
 
  margin-bottom: 20px;/* Ensure it takes full height of parent */
}

.details-text-popup {
 color: #fff;
  border: none;

  font-size:medium;
  font-weight: normal;
  display: flex;
  
  align-items: center;
/* Center horizontally */
  /* Optional: Center text horizontally */
  height: 100%;
  margin-bottom: 20px; /* Ensure it takes full height of parent */
}

.add-button-popup{

  cursor: pointer;
  padding: 12px 50px;
  border-radius: 100px;
  border: none;
  background-color: #007BFF;
  color: white;
  font-weight:bold;
  font-size: medium;
}

.close-button-popup{
  cursor: pointer;
  background-color: white;
  border: none;
 
  color: #007BFF;
  font-weight: normal;
  font-size: medium;
  margin-top: 15px;
}
.credits-text-popup{
  font-size: xx-large;
  font-weight: bold;
  margin-bottom: 20px;
  color: #fff;
}

.checkout-text {
  margin-top: 10px;
  color: gray; /* Adjust the margin-top as needed */
}
.credits-slider{
  cursor: pointer;
width: 80%;
margin-bottom: 20px;
}

.original-price {
  text-decoration: line-through;
  color: gray;
  margin-right: 8px;
}

.discount-text-popup {
  color: green;
  font-weight: bold;
  margin-bottom: 8px;
}



@media screen and (max-width: 768px) {
  .popup-content {
      width: 90vw;
  }
  .credits-text-popup{
      font-size: x-large; 
  }

  .stripe-logo{
      max-width:13vw;
       max-height:10vw;    
   
   }
   .secure-checkout{
      max-width:50vw;
      max-height:25vw;    
       margin-right: 5px;
       font-size: medium;
       color: gray;   
   }
   .add-button-popup{

      cursor: pointer;
      padding: 12px 20px;
     
  }
}

