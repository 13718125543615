.meme-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70vw;
    margin-top: 100px;
    margin-bottom: 10px;
    padding-bottom: 20px;
    background-color: #121212; /* Semi-transparent black background */  
    box-sizing: border-box; /* Include padding and borders in the element's width and height */
    overflow-x: hidden; /* Prevent horizontal overflow */
}

.png-image {
    max-width: 35vw; /* Adjust the percentage to fit two images in a row */
    margin-bottom: 20px;
  }
.selected-image-container {
    margin-bottom: 10px;
}

.meme-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

.selected-image {
    max-width: 100%;
    max-height: 400px;
    object-fit: contain;
}

.thumbnail-container {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    width: 70vw; /* Fixed width */
    margin-bottom: 20px;
    margin-top: 10px;
    background-color: #000;
    box-sizing: border-box; /* Includes padding in the width */
    padding: 0 5px; /* Add padding to the left and right to avoid white space on mobile */
}

.download-button-template {
    background-color: #F0F0F0;
    color: #000000;
    font-weight: bold;
    padding: 12px 25px;
    margin: 8px 0;
    border: none;
    border-radius: 25px;
    cursor: pointer;
}

.thumbnail {
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin-right: 5px;
    cursor: pointer;
    border: 2px solid transparent;
}

.thumbnail:hover {
    border-color: #007bff;
}

.loading-bar-container-meme {
    width: 35vw;
    height: 10px;
    background-color: #e0e0e0;
    overflow: hidden;
}

.loading-bar-meme {
    height: 100%;
    background-color: #007BFF;
    width: 0;
    transition: width 0.4s;
}

.meme-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .png-image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .report-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
  
  .popup-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  textarea {
    width: 100%;
    height: 100px;
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  

@media screen and (max-width: 768px) {
   .loading-bar-container-meme,
    .png-image {
        max-width: 100%;
    }
    .meme-container{
        width: 100vw; 
    }
    .thumbnail-container {
        width: 100vw; /* or a percentage that fits within the viewport */
        padding: 0 10px; /* Adjust padding to avoid white space */
    }

    .loading-bar-wrapper-meme {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .loading-bar-container-meme {
        width: 100%;
        margin: 0 auto; /* Center align horizontally */
    }
}
