/* Layout Styles for Header, Footer, and Centered Form */
.page-container {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Full viewport height */
  }
  

  
  .main-content {
    flex: 1;
    display: flex;
    justify-content: center; /* Horizontally center the form */
    align-items: center; /* Vertically center the form */
    padding: 20px;
  }
  
  .account-deletion-form {
    max-width: 500px;
    width: 100%;
    padding: 20px;
    background-color: #1e1e1e; /* Dark background color */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    color: #fff; /* White text color for contrast */
  }
  
  h2 {
    text-align: center;
    color: #fff;
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    font-size: 14px;
    font-weight: 600;
    color: #ccc; /* Light gray text for labels */
  }
  
  .input-field {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border-radius: 4px;
    border: 1px solid #444; /* Dark border for input fields */
    background-color: #333; /* Dark input field background */
    color: #fff; /* White text color inside the input fields */
    font-size: 14px;
  }
  
  textarea.input-field {
    resize: vertical;
    min-height: 100px;
  }
  
  .submit-button {
    padding: 10px 15px;
    background-color: #007bff; /* Blue button background */
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  
  .confirmation-message {
    text-align: center;
    font-size: 16px;
    color: #28a745; /* Green confirmation message */
    font-weight: 500;
  }
  
  .footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 20px;
    font-size: 16px;
  }
  