.meme-feed-template {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 70vw;
    margin-top: 20px;
  }
  
  .meme-column {
    width: 34.5vw;; /* Adjust as needed */
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .meme-item-template {
    width: 100%;
    object-fit: cover; /* Ensure images fit well */
  }
  
  @media screen and (max-width: 768px) {
    .meme-feed-template {
      flex-direction: column;
      align-items: center; /* Center items horizontally */
    }
    .meme-feed-template,
    .meme-column {
      width: 100%;
    }
  }
  